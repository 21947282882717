import http from './http'
export default {
    getMenudata (params) {
        // 获取加密公钥
        return http.post(`/mapi/getmenudata/`, params)
    },
    getHotnew(params) {
        return http.post(`/mapi/gethotnew`, params)
    },
    getMallcate(params) {
        return http.post(`/mapi/getmallcate`, params)
    },
    getFocusnew(params) {
        return http.post(`/mapi/getfocusnew`, params)
    },
    getLatednew(params) {
        return http.post(`/mapi/getlatedarticle`, params)
    },
    getChannelNew(params) {
        return http.post(`/mapi/getarticlelist`, params)
    },
    getMallData(params) {
        return http.post(`/mapi/getmalldata`, params)
    },
    getArticleData(params) {
        return http.post(`/mapi/getarticledata`, params)
    },
    getProductData(params) {
        return http.post(`/mapi/getproductdata`, params)
    },
    getChannelInfo(params) {
        return http.post(`/mapi/getchanneldata`, params)
    },
    getShareInfo(params) {
        return http.post(`/mapi/getshareinfo`, params)
    }
}
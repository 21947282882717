import request from "@/assets/js/request";
// import { Loading } from 'element-ui'
// import { JSEncrypt } from 'encryptlong'
// import {Base64} from 'js-base64'
// function JSRSAEncrypt (input) {
//   var result = ''
//   if (sessionStorage.getItem('PublicKey')) {
//     var publicKey = sessionStorage.getItem('PublicKey')
//     const encryptor = new JSEncrypt()
//     encryptor.setPublicKey(publicKey)
//     // 普通的就是return encryptor.encrypt(passwd)
//     result = encryptor.encryptLong(input)
//   }
//   return result
// }
const http = {
  get: function (url, params) {
    const config = {
      method: "get",
      url: url,
    };
    if (params) {
      params.time = Date.parse(new Date());
      params.version = 1;
    } else {
      params = {
        time: Date.parse(new Date()),
      };
    }
    config.params = params;
    return request(config);
    // var token = JSRSAEncrypt(JSON.stringify(params))
    // var data = {'data': token}
    // config.params = data
  },
  post: function (url, params) {
    const config = {
      method: "post",
      url: url,
    };
    if (params) {
      params.time = Date.parse(new Date());
      if (!params.version) {
        params.version = 1;
      }
    } else {
      params = {
        time: Date.parse(new Date()),
      };
    }
    config.params = params;
    return request(config);
    // var token = JSRSAEncrypt(Base64.encode(JSON.stringify(params)))
    // var data = {'data': token}
    // config.params = data
    // return request(config)
  },
};
export default http;
